:root {
  --header-height: 80px;
}
.Carousel {
  width: 100%;
  height: 100vh;
  max-height: 1050px;
  overflow: hidden;
  position: relative;
}
.mySwiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.carousel-text {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 30px;
  font-weight: 600;
  z-index: 10;
  padding-left: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.carousel-text > h1 {
  font-size: 35px;
  font-weight: 600;
  color: white;
}
.carousel-text > p {
  font-size: 18px;
  font-weight: 400;
  color: white;
}
.carousel-text > button {
  margin-top: 20px;
  background-color: white;
  color: black;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 15px 0;
  width: 140px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;
}
.carousel-text > button:hover {
  background-color: rgb(32, 136, 255);
  color: white;
}
.swiper-pagination-bullet {
  background: white;
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  opacity: 1;
}
.swiper-button-next,
.swiper-button-prev {
  color: white;
}
.swiper-pagination {
  position: absolute;
  right: 45px;
  left: auto !important;
  bottom: 45px !important;
  width: auto !important;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.5;
  margin: 0 !important;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: white !important;
  color: white;
}

@media (max-width: 800px) {
  .carousel-text {
    padding-left: 15px;
    padding-bottom: 55px;
  }
  .swiper-pagination {
    bottom: 55px !important;
  }
  .carousel-text > h1 {
    font-size: 30px;
  }
  .carousel-text > p {
    font-size: 16px;
  }
  .carousel-text > button {
    margin-top: 10px;
  }
}
