.Catalog {
  margin-top: 100px;
  padding: 0 45px;
  margin-bottom: 10px;
  padding-bottom: 100px;
  box-shadow: 0px 5px 5px rgba(181, 181, 181, 0.05);
}
.catalog-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.catalog-item {
  background-color: #f5f6f8;
  transition: 0.15s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.image-wrapper {
  width: 100%;
  aspect-ratio: 4 / 3;
  overflow: hidden;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Catalog h2 {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
}
.catalog-item:hover .title {
  color: rgb(32, 136, 255);
}
.catalog-item:hover {
  transform: scale(1.02);
}

@media (max-width: 800px) {
  .Catalog {
    padding: 0 20px;
    padding-bottom: 50px;
  }
  .catalog-items {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
