@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", "Noto Sans Armenian", serif;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Noto Sans Armenian", serif;
}
ul {
  list-style: none;
}
