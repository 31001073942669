.Footer {
  background-color: white;
  width: 100%;
  display: flex;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 45px;
}
.footer > div {
  flex: 1;
  display: flex;
  gap: 15px;
  flex-direction: column;
  max-width: 300px;
}
.Footer h1 {
  font-size: 22px;
  font-weight: 400;
  color: rgb(31, 31, 31);
}
.Footer p {
  font-size: 14px;
  font-weight: 300;
  text-wrap: wrap;
  color: rgb(31, 31, 31);
  width: fit-content;
  padding-right: 30px;
}
.social-media-icons {
  display: flex;
  gap: 15px;
}
.icon {
  color: rgb(31, 31, 31);
  transition: color 0.15s;
  cursor: pointer;
}
.icon:hover {
  color: rgb(86, 86, 86);
}
.option {
  border-bottom: 1px solid rgba(32, 136, 255, 0);
  cursor: pointer;
  transition: 0.15s;
}
.option:hover {
  color: rgb(32, 136, 255);
  border-bottom: 1px solid rgb(32, 136, 255);
}

@media (max-width: 1000px) {
  .footer {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  .footer > div {
    max-width: 100%;
    align-items: center;
  }
  .Footer p {
    text-align: center;
    padding: 0;
  }
}
